<template>
  <basic-form-wrapper class="basic-form-inner">
    <div class="atbd-review-order theme-light" :style="{ width: '100%' }">
      <sd-heading as="h4">{{ $t('views.users.review.title') }}</sd-heading>
      <sdCards :bodyStyle="bodyStyle" headless>
        <div class="atbd-review-order__single">
          <sdCards headless>
            <div class="atbd-review-order__shippingTitle">
              <sdHeading as="h5">{{ $t('layout.users.invite.details') }}</sdHeading>
            </div>
            <article class="pl-20 atbd-review-order__shippingInfo">
              {{ $t('views.users.details.name') }}
              {{user.details.first_name + ' ' + user.details.last_name}}
            </article>
            <article class="pl-20 atbd-review-order__shippingInfo">
              {{ $t('views.users.details.email') }} {{user.details.email}}
            </article>
          </sdCards>
        </div>
        <div class="atbd-review-order__single">
          <sdCards headless>
            <div>
              <sdHeading as="h5">{{ $t('layout.users.invite.permissions') }}</sdHeading>
            </div>
            <article v-for="permission in allPermissions" :key="permission.id"
                     class="pl-20 atbd-review-order__shippingInfo">
              {{permission.displayName}}
            </article>
            <div v-if="user.permissions.length <= 0">
              N/A
            </div>
          </sdCards>
        </div>
      </sdCards>
    </div>
    <steps-form-navigation nextLabel="actions.done"
                           nextIcon=""
                           :loading="loading"
                           @nextStep="$emit('submitted')"
                           @previousStep="$emit('previousStep')"/>
  </basic-form-wrapper>
</template>
<script>
import {BasicFormWrapper} from '@/components/shared/styledComponents/Main';
import StepsFormNavigation from '@/components/shared/form/StepsFormNavigation';
import {computed, defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';

export default defineComponent({
  name: 'ReviewUser',
  emits: ['submitted', 'previousStep'],
  props: {
    user: VueTypes.object.required,
    loading: VueTypes.bool.def(false)
  },
  components: {
    BasicFormWrapper,
    StepsFormNavigation
  },
  setup(props) {
    const {state} = useStore();

    const allPermissions = computed(() => {
      return state.users.permissions.filter((permission) => props.user.permissions.includes(permission.id));
    });

    return {
      allPermissions,
      bodStyle: {backgroundColor: '#F8F9FB', borderRadius: '10px'}
    };
  }
});
</script>

<style>
.text-left {
  text-align: left !important;
}
</style>
