<template>
  <basic-form-wrapper class="basic-form-inner">
    <div class="atbd-form-checkout">
      <a-row justify="center">
        <a-col :sm="22" :xs="24">
          <div class="create-account-form">
            <sd-heading as="h4">{{ $t('views.users.details.title') }}</sd-heading>
            <app-form :fields="fields" :submitButton="submitButton" @submitted="handleSubmit"/>
          </div>
        </a-col>
      </a-row>
    </div>
  </basic-form-wrapper>
</template>
<script>
import {BasicFormWrapper} from '@/components/shared/styledComponents/Main';
import {defineComponent, reactive} from 'vue';
import VueTypes from 'vue-types';
import {
  required, email, availableEmail
} from '@/helpers/validationRules';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'userDetailsForm',
  emits: ['user:submitted'],
  props: {
    details: VueTypes.object.required,
    withoutUserType: VueTypes.bool.def(false),
    loading: VueTypes.bool.def(false)
  },
  components: {
    BasicFormWrapper
  },
  setup(props, context) {
    const {t} = useI18n();

    const fields = reactive({
      first_name: {
        type: 'text',
        fieldValue: props.details.first_name,
        label: 'views.users.details.firstName',
        placeholder: 'views.users.details.firstName',
        rules: [required],
        class: 'mb-20',
        styles: {
          sm: 24,
          md: 12
        }
      },
      last_name: {
        type: 'text',
        fieldValue: props.details.last_name,
        label: 'views.users.details.lastName',
        placeholder: 'views.users.details.lastName',
        rules: [required],
        class: 'mb-20',
        styles: {
          sm: 24,
          md: 12
        }
      },
      email: {
        type: 'text',
        fieldValue: props.details.email,
        label: 'views.users.details.emailAddress',
        placeholder: 'views.users.details.emailAddress',
        rules: [required, email, availableEmail],
        styles: {md: 24},
        class: 'mb-20'
      },
      ...(!props.withoutUserType && {
        userType: {
          type: 'select',
          fieldValue: props.details.user_type,
          label: 'models.user.attributes.userType',
          placeholder: 'models.user.attributes.userType',
          options: [
            {
              value: 'primary_user',
              label: t('models.user.attributes.userTypes.primary_user')
            },
            {
              value: 'secondary_user',
              label: t('models.user.attributes.userTypes.secondary_user')
            }
          ],
          styles: {md: 24},
          class: 'mb-20'
        }
      })
    });

    const submitButton = reactive({
      type: 'primary',
      block: false,
      label: 'actions.saveAndNext',
      icon: 'arrow-right',
      classes: 'float-right'
    });

    const handleSubmit = (data) => context.emit('user:submitted', data);

    return {
      fields,
      submitButton,
      handleSubmit
    };
  }
});
</script>
