<template>
  <basic-form-wrapper class="basic-form-inner">
    <div class="atbd-form-checkout">
      <a-row justify="center">
        <a-col :sm="22" :xs="24">
          <div class="create-account-form">
            <sd-heading class="mb-0" as="h4">{{ $t('views.users.permissions.title') }}</sd-heading>
            <table-style-wrapper>
              <table-wrapper class="table-responsive">
                <a-table :row-selection="{ selectedRowKeys: selectedPermissions.value, onChange: onSelectChange }"
                         :columns="columns"
                         :data-source="allPermissions"
                         :rowKey="(record) => `${record.id}`"
                         :pagination="false"/>
              </table-wrapper>
            </table-style-wrapper>
            <steps-form-navigation nextLabel="actions.saveAndNext"
                                   :loading="loading"
                                   @nextStep="$emit('permissions:submitted', selectedPermissions.value)"
                                   @previousStep="$emit('previousStep', selectedPermissions.value)"/>
          </div>
        </a-col>
      </a-row>
    </div>
  </basic-form-wrapper>
</template>
<script>
import {BasicFormWrapper} from '@/components/shared/styledComponents/Main';
import {TableStyleWrapper} from '@/components/tables/style.js';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import StepsFormNavigation from '@/components/shared/form/StepsFormNavigation';
import {
  computed, defineComponent, reactive
} from 'vue';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'userDetailsForm',
  emits: ['permissions:submitted', 'previousStep'],
  props: {
    permissions: VueTypes.object.required,
    loading: VueTypes.bool.def(false)
  },
  components: {
    BasicFormWrapper,
    TableStyleWrapper,
    TableWrapper,
    StepsFormNavigation
  },
  setup(props) {
    const {state, dispatch} = useStore();
    const {t} = useI18n();

    const selectedPermissions = reactive({value: props.permissions});
    const allPermissions = computed(() => state.users.permissions);
    dispatch('getPermissions');

    const columns = computed(() =>[{
      title: t('views.users.permissions.index'),
      dataIndex: 'displayName',
      className: 'text-left'
    }]);

    const onSelectChange = (selectedRowKeys) => {
      selectedPermissions.value = selectedRowKeys;
    };

    return {
      allPermissions,
      selectedPermissions,
      columns,
      onSelectChange
    };
  }
});
</script>

<style>
 .text-left {
   text-align: left !important;
 }
</style>
