<template>
  <a-row justify="start" :style="{ width: '100%' }">
    <a-col :xs="24">
      <div class="checkout-successful">
        <sd-cards headless :bodyStyle="bodyStyle">
          <sd-cards headless>
                    <span class="icon-success">
                      <sd-feather-icons type="check"/>
                    </span>
            <sd-heading as="h3">{{ $t('views.users.review.success.title') }}</sd-heading>
            <p>{{ $t('views.users.review.success.body') + name }}</p>
          </sd-cards>
        </sd-cards>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'ReviewSuccess',
  props: {
    name: VueTypes.string.required,
    loading: VueTypes.bool.def(false)
  },
  setup() {
    return {
      bodyStyle: {backgroundColor: '#F8F9FB', borderRadius: '20px'}
    };
  }
});
</script>
