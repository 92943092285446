<template>
  <div>
    <app-header :routes="routes"/>
    <Main>
      <a-row :gutter="15">
        <a-col :xs="24">
          <sd-cards headless class="p-20">
            <div class="wizard-side-border invite-users-steps">
              <wizard-wrapper>
                <wizard-six>
                  <Steps isSwitch
                         isVertical
                         :current=current
                         :status="status"
                         :steps="steps"
                         :isFinished="isFinished">
                    <template #userDetails>
                      <details-form :details="user.details"
                                    :without-user-type="isBackOffice"
                                    @user:submitted="handleUserDetailsSubmit"/>
                    </template>
                    <template #permissions>
                      <permissions-form :permissions="user.permissions"
                                        @permissions:submitted="handlePermissionsSubmit"
                                        @previousStep="handlePermissionsPreviousStep"/>
                    </template>
                    <template #review>
                      <review-user :loading="loading"
                                   :user="user"
                                   @submitted="handleSubmit"
                                   @previousStep="handlePreviousStep"/>
                    </template>
                    <template #success>
                      <invitation-success :name="user.details.first_name"/>
                    </template>
                  </Steps>
                </wizard-six>
              </wizard-wrapper>
            </div>
          </sd-cards>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>
<script>
import DetailsForm from '@/components/users/DetailsForm';
import InvitationSuccess from '@/components/users/InvitationSuccess';
import PermissionsForm from '@/components/users/PermissionsForm';
import ReviewUser from '@/components/users/ReviewUser';
import {Main} from '@/components/shared/styledComponents/Main';
import Steps from '@/components/steps/steps';
import {WizardWrapper, WizardSix} from '@/components/shared/styledComponents/Wizard';
import {
  ref, defineComponent, reactive, computed, watch
} from 'vue';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import router from '@/routes';

export default defineComponent({
  name: 'inviteUsers',
  components: {
    DetailsForm,
    InvitationSuccess,
    PermissionsForm,
    ReviewUser,
    Main,
    Steps,
    WizardWrapper,
    WizardSix
  },
  setup() {
    const {t} = useI18n();
    const {state, dispatch} = useStore();
    const loading = ref(false);
    const currentUser = computed(() => state.session.currentUser);
    const status = ref('process');
    const isBackOffice = computed(() => state.session.userType === 'BackOfficeUser');
    const isFinished = ref(false);
    const current = ref(0);
    const user = reactive({
      details: {},
      permissions: []
    });

    const routes = computed(() => [
      {
        name: `${routePrefix.value}Users`,
        breadcrumbName: 'layout.users.title'
      },
      {
        name: `${routePrefix.value}InviteUser`,
        breadcrumbName: 'layout.users.invite.subTitle'
      }
    ]);

    const handleUserDetailsSubmit = (details) => {
      user.details = details;
      current.value++;
    };

    const handlePermissionsSubmit = (permissions) => {
      user.permissions = permissions;
      current.value++;
    };

    const handlePermissionsPreviousStep = (permissions) => {
      user.permissions = permissions;
      current.value--;
    };

    const handleSubmit = async () => {
      loading.value = true;
      const response = await dispatch('inviteUser', {...user.details, permission_ids: user.permissions});
      if (response === 200) {
        current.value++;
      }
      loading.value = false;
    };

    const handlePreviousStep = () => {
      current.value--;
    };

    const routePrefix = computed(() => {
      switch (currentUser.value.type) {
      case 'BackOfficeUser':
        return 'BackOffice';
      case 'ClientUser':
        return 'Client';
      default:
        return 'Agent';
      }
    });

    watch(current, (value) => {
      if (value === 3) {
        const redirect = setInterval(() => {
          router.push({name: `${routePrefix.value}Users`});
          clearInterval(redirect);
        }, 4000);
      }
    });

    return {
      loading,
      status,
      isFinished,
      isBackOffice,
      current,
      user,
      routes,
      handleUserDetailsSubmit,
      handlePermissionsSubmit,
      handlePermissionsPreviousStep,
      handleSubmit,
      handlePreviousStep,
      checked: ref(false),
      steps: [{
        title: '',
        content: 'userDetails',
        subTitle: t('layout.users.invite.details'),
        class: 'invite-users-steps',
        disabled: true
      }, {
        title: '',
        content: 'permissions',
        subTitle: t('layout.users.invite.permissions'),
        class: 'invite-users-steps',
        disabled: true
      }, {
        title: '',
        content: 'review',
        subTitle: t('layout.users.invite.review'),
        class: 'invite-users-steps',
        disabled: true
      }, {
        title: '',
        content: 'success',
        class: 'hidden',
        disabled: true
      }]
    };
  }
});
</script>

<style>
.invite-users-steps {
  min-width: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center !important;
}
.hidden {
  display: none !important;
}
</style>
